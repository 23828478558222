//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> File upload
import Dropzone from "react-dropzone";
//> File crop
import Cropper from "react-easy-crop";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBCol,
  MDBRangeInput,
  MDBRow,
  MDBIcon,
  MDBAlert,
  MDBView,
  MDBMask,
  MDBInput,
  MDBBtn,
} from "mdbreact";
//> Textarea
import TextareaAutosize from "react-textarea-autosize";
//#endregion

//#region > Functions
// Closure
(function () {
  /**
   * Decimal adjustment of a number.
   *
   * @param {String}  type  The type of adjustment.
   * @param {Number}  value The number.
   * @param {Integer} exp   The exponent (the 10 logarithm of the adjustment base).
   * @returns {Number} The adjusted value.
   */
  function decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split("e");
    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  }

  // Decimal round
  if (!Math.round10) {
    Math.round10 = function (value, exp) {
      return decimalAdjust("round", value, exp);
    };
  }
  // Decimal floor
  if (!Math.floor10) {
    Math.floor10 = function (value, exp) {
      return decimalAdjust("floor", value, exp);
    };
  }
  // Decimal ceil
  if (!Math.ceil10) {
    Math.ceil10 = function (value, exp) {
      return decimalAdjust("ceil", value, exp);
    };
  }
})();
//#endregion

//#region > Components
class ImageSelector extends React.Component {
  state = { selectedFiles: [], rows: 1 };
  containerRef = React.createRef();

  setValue = (pos, val) => {
    this.setState({
      ["textarea-" + pos]: val,
    });
  };

  componentDidMount = () => {
    const { savedState } = this.props;

    if (savedState) {
      this.setState({
        rotation: savedState.rotation,
        zoom: savedState.zoom,
        crop: savedState.crop,
        selectedFiles: savedState.selectedFiles,
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { savedState } = this.props;

    if (
      savedState &&
      savedState.selectedFiles[0]?.preview !==
        this.state.selectedFiles[0]?.preview
    ) {
      this.setState({
        rotation: savedState.rotation,
        zoom: savedState.zoom,
        crop: savedState.crop,
        selectedFiles: savedState.selectedFiles,
      });
    } else {
      if (
        savedState === undefined &&
        this.state.selectedFiles.length > 0 &&
        !this.state.setImage
      ) {
        this.setState({
          rotation: 0,
          zoom: undefined,
          crop: undefined,
          selectedFiles: [],
        });
      } else {
        if (savedState && this.state.selectedFiles.length === 0) {
          this.setState({
            rotation: savedState.rotation,
            zoom: savedState.zoom,
            crop: savedState.crop,
            selectedFiles: savedState.selectedFiles,
          });
        }
      }
    }
  };

  getDrop = (files) => {
    const res = files.map((file) => {
      return {
        data: file,
        preview: URL.createObjectURL(file),
      };
    });

    // Get image dimensions
    let img = new Image();

    img.onload = () => {
      const height = img.height;
      const width = img.width;

      // Get max scroll
      let maxScroll = 3;

      if ((width > 1000 && width < 1654) || (height > 1000 && height < 1654)) {
        maxScroll = 4;
      } else if (width > 1654 || height > 1654) {
        maxScroll = 10;
      }

      const zoomValue = height > width ? height / width : width / height;
      const fixedValue = Math.round10(zoomValue, -1);

      this.setState({
        dimensions: { height, width },
        maxScroll,
        minScroll: fixedValue,
        zoom: fixedValue,
      });
    };

    img.src = res[0].preview;

    this.setState({
      selectedFiles: res,
      status: "",
      setImage: true,
    });
  };

  removeDrop = () => {
    this.setState(
      {
        selectedFiles: [],
        status: "",
        setImage: false,
      },
      () => {
        this.props.removeImage(this.props.id);
      }
    );
  };

  showCroppedImage = async (rotation, crop, zoom, croppedAreaPixels) => {
    this.setState(
      {
        croppedAreaPixels,
      },
      () => {
        this.props.saveImage(
          this.state.selectedFiles,
          crop,
          rotation,
          zoom,
          croppedAreaPixels,
          this.props.id
        );
      }
    );
  };

  calculateQuality = () => {
    const dimensions = this.state.croppedAreaPixels;

    if (dimensions) {
      const { width, height } = dimensions;

      if (width < 386 || height < 386) {
        return {
          score: 0,
          text: "Achtung! Stark verminderte Bildqualität.",
          color: "danger",
          icon: "times-circle",
          width,
          height,
          maxScroll: 3,
        };
      } else if (width < 500 || height < 500) {
        return {
          score: 1,
          text: "Achtung! Verminderte Bildqualität.",
          color: "warning",
          icon: "exclamation-triangle",
          width,
          height,
          maxScroll: 3,
        };
      } else if (width < 1654 || height < 1654) {
        return {
          score: 2,
          text: "Bildqualität gut",
          color: "info",
          icon: "check",
          width,
          height,
          maxScroll: 4,
        };
      } else {
        return {
          score: 3,
          text: "Bildqualität optimal",
          color: "success",
          icon: "check-circle",
          width,
          height,
          maxScroll: 10,
        };
      }
    } else {
      return null;
    }
  };

  render() {
    const quality = this.calculateQuality();
    const { dimensions } = this.state;

    return (
      <>
        <div className="half-selector example">
          <MDBView>
            <img
              src={require("../../../assets/blank.jpg")}
              alt="Blank Plauderkiste"
              className="img-fluid"
            />
            <MDBMask className="d-flex flex-center">
              <div className="image">
                {this.state.selectedFiles.length === 0 ? (
                  <Dropzone
                    onDrop={(acceptedFiles) => this.getDrop(acceptedFiles)}
                    onDropRejected={() =>
                      this.setState({
                        status: "reject",
                      })
                    }
                    onDragOver={() =>
                      this.setState({
                        status: "active",
                      })
                    }
                    onDragLeave={() => this.setState({ status: "" })}
                    accept="image/jpeg, image/png"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        className={"wrapper clickable " + this.state.status}
                        style={
                          this.state.selectedFiles && {
                            backgroundImage: `url("${this.state.selectedFiles[0]?.preview}")`,
                          }
                        }
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <p>
                          Ziehe Dateien hierher oder klicke auf das Feld, um
                          Dateien auszuwählen
                        </p>
                      </div>
                    )}
                  </Dropzone>
                ) : (
                  <div>
                    <div className="cropper-hopper" ref={this.containerRef}>
                      <Cropper
                        showGrid={false}
                        image={this.state.selectedFiles[0].preview}
                        crop={
                          this.state.crop ? this.state.crop : { x: 0, y: 0 }
                        }
                        zoom={this.state.zoom}
                        maxZoom={
                          this.state.maxScroll ? this.state.maxScroll : 3
                        }
                        minZoom={
                          this.state.minScroll ? this.state.minScroll : 1
                        }
                        rotation={this.state.rotation}
                        cropSize={{
                          width: this.containerRef.current
                            ? this.containerRef.current.clientWidth * 0.94
                            : 200,
                          height: this.containerRef.current
                            ? this.containerRef.current.clientHeight * 0.91
                            : 200,
                        }}
                        aspect={16 / 9}
                        onCropChange={(crop) => this.setState({ crop })}
                        onCropComplete={(croppedArea, croppedAreaPixels) =>
                          this.showCroppedImage(
                            this.state.rotation,
                            this.state.crop,
                            this.state.zoom,
                            croppedAreaPixels
                          )
                        }
                        onZoomChange={(zoom) => this.setState({ zoom })}
                        onRotationChange={(rotate) => this.setState({ rotate })}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="text">
                <TextareaAutosize
                  value={this.props.value}
                  onChange={(e) => this.props.getValue(e.target.value)}
                  maxLength={115}
                  minRows={3}
                  maxRows={3}
                  className="w-100 my-2"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") e.preventDefault();
                  }}
                />
              </div>
            </MDBMask>
          </MDBView>
        </div>
        {quality && (
          <div className="mt-3">
            <MDBAlert color={quality.color}>
              <p className="mb-0">
                <MDBIcon icon={quality.icon} className="mr-2" />
                {quality.text}
                {quality.score <= 1 && this.state.dimensions?.maxScroll < 4 && (
                  <p className="small mb-0">
                    Es wird eine minimale Bildauflösung von 1000 x 1000px
                    empfohlen.
                  </p>
                )}
              </p>
            </MDBAlert>
          </div>
        )}
        {this.state.selectedFiles.length > 0 && (
          <>
            <MDBRow className="mt-3">
              <MDBCol lg="6">
                <span className="d-block text-muted">
                  <MDBIcon icon="crop-alt" /> Zoom
                </span>
                <MDBRangeInput
                  min={this.state.minScroll ? this.state.minScroll : 1}
                  max={this.state.maxScroll ? this.state.maxScroll : 3}
                  value={this.state.zoom ? this.state.zoom : 0}
                  step={0.1}
                  className="zoom-range"
                  getValue={(val) => this.setState({ zoom: val })}
                />
              </MDBCol>
              <MDBCol lg="6">
                <span className="d-block text-muted">
                  <MDBIcon icon="sync-alt" /> Rotieren
                </span>
                <MDBRangeInput
                  min={-90}
                  max={90}
                  step={5}
                  value={this.state.rotation ? this.state.rotation : 0}
                  getValue={(val) => this.setState({ rotation: val })}
                />
              </MDBCol>
            </MDBRow>
            <MDBBtn color="red" outline onClick={this.removeDrop}>
              Bild entfernen
            </MDBBtn>
            <MDBBtn
              color="blue"
              outline
              onClick={() =>
                this.setState({
                  zoom: this.state.minScroll ? this.state.minScroll : 1,
                  rotation: 0,
                })
              }
            >
              Zurücksetzen
            </MDBBtn>
          </>
        )}
      </>
    );
  }
}
//#endregion

//#region > Exports
export default ImageSelector;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
