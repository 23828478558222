//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { withRouter } from "react-router-dom";
//> Animations
import { animations } from "react-animation";
//> UUID
import { v4 as uuidv4 } from "uuid";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCarousel,
  MDBCarouselItem,
  MDBCarouselInner,
  MDBView,
  MDBMask,
  MDBInput,
  MDBProgress,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBEdgeHeader,
  MDBFreeBird,
  toast,
  ToastContainer,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> Components
import { ImageSelector, TextSelector } from "../../../molecules";
//> Redux
import { addVariantToCart } from "../../../../store/actions/shopifyActions";
//> Helpers
import getCroppedImg from "../../../../helpers/cropImage";
import {
  convertBlobToBase64,
  fetchAsBlob,
  b64toBlob,
} from "../../../../helpers/blobToBase64";
//#endregion

//#region > Config
const styleFadeIn = {
  animation: `${animations.popIn}`,
};
//#endregion

//#region > Components
class Standard extends React.Component {
  state = {
    slide: 1,
    results: [],
    successfulRendered: 0,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.props.selection) {
      this.getData();
    }
  };

  componentWillUnmount = () => {
    localStorage.removeItem(
      "edit-" + this.props.selection.variants.edges[0].node.id
    );
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.selection !== this.props.selection && this.props.selection) {
      this.getData();
    }
  };

  getData = async () => {
    const { editData } = this.props;

    const ls = localStorage.getItem(
      "edit-" + this.props.selection.variants.edges[0].node.id
    )
      ? localStorage.getItem(
          "edit-" + this.props.selection.variants.edges[0].node.id
        )
      : localStorage.getItem(this.props.selection.variants.edges[0].node.id);

    const massiveVoid = ls ? JSON.parse(ls).massiveVoid : null;

    if (massiveVoid || editData) {
      this.setState({
        loadingExisting: true,
      });

      const res = await fetch(process.env.REACT_APP_BASEURL + "plauder/get/", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          id: editData ? editData.massiveVoid : massiveVoid,
        }),
      })
        .then(async (response) => {
          const data = await response.json();
          const res = JSON.parse(data.void);

          if (res) {
            let bar = new Promise(async (resolve, reject) => {
              let temp = {};
              let counter = 0;

              res.result.forEach(async (element, e) => {
                if (element) {
                  const img = await b64toBlob(res.details[e].image);

                  temp["textarea-" + e] = element.text;
                  temp["save-" + e] = {
                    selectedFiles: [
                      {
                        preview: URL.createObjectURL(img),
                      },
                    ],
                    crop: res.details[e].crop,
                    zoom: res.details[e].zoom ? res.details[e].zoom : 1,
                    croppedAreaPixels: res.details[e].croppedAreaPixels,
                    rotation: res.details[e].rotation
                      ? res.details[e].rotation
                      : 0,
                    minScroll: res.details[e].minScroll
                      ? res.details[e].minScroll
                      : 1,
                  };

                  if (img) {
                    counter++;
                  }

                  if (counter === this.props.count) {
                    resolve(temp);
                  }
                } else {
                  counter++;
                }
              });
            });

            bar.then((tempRes) => {
              this.setState(
                {
                  ...tempRes,
                  loadingExisting: false,
                },
                () => this.renderPreview(false)
              );
            });
          }
        })
        .catch((error) => {
          console.error(error);

          this.setState({
            fetchError: true,
          });
        });
    }
  };

  save = (doSave) => {
    const { editData } = this.props;
    let res = [];

    let bar = new Promise(async (resolve, reject) => {
      let resDetails = [];

      for (let index = 0; index < this.props.count; index++) {
        res = [...res, this.state["result-" + index]];

        await fetchAsBlob(this.state["save-" + index].selectedFiles[0].preview)
          .then(convertBlobToBase64)
          .then((resBase64) => {
            resDetails = [
              ...resDetails,
              {
                image: resBase64,
                crop: this.state["save-" + index].crop,
                zoom: this.state["save-" + index].zoom,
                croppedAreaPixels: this.state["save-" + index]
                  .croppedAreaPixels,
                rotation: this.state["save-" + index].rotation,
                minScroll: this.state["save-" + index].minScroll
                  ? this.state["save-" + index].minScroll
                  : 1,
              },
            ];
          });

        if (index === this.props.count - 1) {
          resolve(resDetails);
        }
      }
    });

    bar.then((resultDetailReturn) => {
      // Get localStorage entry
      const ls = localStorage.getItem(
        "edit-" + this.props.selection.variants.edges[0].node.id
      )
        ? localStorage.getItem(
            "edit-" + this.props.selection.variants.edges[0].node.id
          )
        : localStorage.getItem(this.props.selection.variants.edges[0].node.id);

      // Get massiveVoid id
      const uuid = editData
        ? editData.massiveVoid
        : ls
        ? JSON.parse(ls).massiveVoid
        : uuidv4();

      // Create data object
      const data = {
        variantId: this.props.selection.variants.edges[0].node.id,
        result: res,
        details: resultDetailReturn,
      };

      if (doSave) {
        this.setState({ doingSave: true });

        fetch(process.env.REACT_APP_BASEURL + "plauder/save/", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            massive_void: JSON.stringify(data),
            id: uuid,
          }),
        })
          .then((response) => {
            switch (response.status) {
              case 200: // OK
                this.setState({ doingSave: false });

                return { status: true };
              case 400: // Bad Request
                this.setState({ doingSave: false });

                return { status: false };
              default:
                this.setState({ doingSave: false });

                return { status: false };
            }
          })
          .catch((error) => {
            return { status: false, error };
          });
      }

      // Set results in state
      this.setState({
        results: data,
      });

      if (!ls || (ls && !JSON.parse(ls).massiveVoid && !editData)) {
        // Link line item with massive void id
        localStorage.setItem(
          this.props.selection.variants.edges[0].node.id,
          JSON.stringify({
            variantId: this.props.selection.variants.edges[0].node.id,
            massiveVoid: uuid,
          })
        );
      }
    });
  };

  setValue = (pos, val) => {
    this.setState({
      ["textarea-" + pos]: val,
    });
  };

  cropImage = (croppedImage, id) => {
    this.setState({
      ["result-" + id]: croppedImage,
    });
  };

  saveImage = (
    selectedFiles,
    crop,
    rotation,
    zoom,
    croppedAreaPixels,
    id,
    minScroll
  ) => {
    this.setState({
      ["save-" + id]: {
        selectedFiles,
        crop,
        zoom,
        croppedAreaPixels,
        rotation,
        minScroll,
      },
    });
  };

  removeImage = (id) => {
    this.setState({
      ["save-" + id]: undefined,
    });
  };

  saveDraft = () => {
    const { editData } = this.props;

    this.setState({ isLoading: true });

    let res = [];
    let details = [];
    let saves = [];

    let results = new Promise(async (resolve, reject) => {
      for (let index = 0; index < this.props.count; index++) {
        saves = [
          ...saves,
          {
            image: this.state["save-" + index]
              ? this.state["save-" + index]
              : null,
            text: this.state["textarea-" + index]
              ? this.state["textarea-" + index]
              : null,
          },
        ];
      }

      let counter = 0;

      saves.forEach(async (element, e) => {
        if (element.image) {
          const croppedImage = await getCroppedImg(
            element.image.selectedFiles[0]?.preview,
            element.image.croppedAreaPixels,
            element.image.rotation ? element.image.rotation : 0
          );

          this.setState({
            ["result-" + e]: {
              text: element.text,
              renderedImage: croppedImage,
            },
          });
        } else {
          this.setState({
            ["result-" + e]: {
              image: null,
              text: null,
              renderedImage: null,
            },
          });
        }

        counter++;

        if (counter === this.props.count) {
          resolve(saves);
        }
      });
    });

    results.then((resultReturn) => {
      let bar = new Promise(async (resolve, reject) => {
        for (let index = 0; index < this.props.count; index++) {
          if (saves[index].image && saves[index].text) {
            res = [...res, this.state["result-" + index]];

            await fetchAsBlob(
              this.state["save-" + index].selectedFiles[0].preview
            )
              .then(convertBlobToBase64)
              .then((resBase64) => {
                details = [
                  ...details,
                  {
                    image: resBase64,
                    crop: this.state["save-" + index].crop,
                    zoom: this.state["save-" + index].zoom,
                    croppedAreaPixels: this.state["save-" + index]
                      .croppedAreaPixels,
                    rotation: this.state["save-" + index].rotation,

                    minScroll: this.state["save-" + index].minScroll
                      ? this.state["save-" + index].minScroll
                      : 1,
                  },
                ];
              });
          } else {
            res = [...res, null];
            details = [...details, null];
          }

          if (index === this.props.count - 1) {
            resolve(details);
          }
        }
      });

      bar.then((resultDetailReturn) => {
        // Get localStorage entry
        const variantId = this.props.selection.variants.edges[0].node.id;

        const ls = localStorage.getItem("edit-" + variantId)
          ? localStorage.getItem("edit-" + variantId)
          : localStorage.getItem(variantId);

        // Get massiveVoid id
        const uuid = editData
          ? editData.massiveVoid
          : ls
          ? JSON.parse(ls).massiveVoid
          : uuidv4();
        // Create data object
        const data = {
          variantId: this.props.selection.variants.edges[0].node.id,
          result: res,
          details: resultDetailReturn,
        };

        fetch(process.env.REACT_APP_BASEURL + "plauder/save/", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            massive_void: JSON.stringify(data),
            id: uuid,
          }),
        })
          .then((response) => {
            this.setState({ isLoading: false });

            switch (response.status) {
              case 200: // OK
                toast.success("Der Entwurf wurde gespeichert.", {
                  closeButton: false,
                });
                return { status: true };
              case 400: // Bad Request
                toast.error("Der Entwurf konnte nicht gespeichert werden.", {
                  closeButton: false,
                });
                return { status: false };
              default:
                return { status: false };
            }
          })
          .catch((error) => {
            return { status: false, error };
          });

        // Set results in state
        this.setState({
          results: data,
        });

        if (!ls || (ls && !JSON.parse(ls).massiveVoid && !editData)) {
          // Link line item with massive void id
          localStorage.setItem(
            this.props.selection.variants.edges[0].node.id,
            JSON.stringify({
              variantId: this.props.selection.variants.edges[0].node.id,
              massiveVoid: uuid,
            })
          );
        }
      });
    });
  };

  renderPreview = (doSave = true) => {
    this.setState({
      successfulRendered: 0,
    });

    let saves = [];
    let counter = 0;

    for (let index = 0; index < this.props.count; index++) {
      saves = [
        ...saves,
        {
          image: this.state["save-" + index],
          text: this.state["textarea-" + index],
        },
      ];
    }

    saves.forEach(async (element, e) => {
      if (element.image) {
        const croppedImage = await getCroppedImg(
          element.image.selectedFiles[0]?.preview,
          element.image.croppedAreaPixels,
          element.image.rotation ? element.image.rotation : 0
        );

        if (this.cropImage) {
          counter++;
        }

        if (counter === this.props.count && doSave) {
          this.save(true);
        }

        this.setState({
          ["result-" + e]: {
            text: element.text,
            renderedImage: croppedImage,
          },
          successfulRendered:
            this.state.successfulRendered === this.props.count
              ? 0
              : this.state.successfulRendered + 1,
        });
      } else {
        this.setState({
          ["result-" + e]: {
            image: element.image,
            text: element.text,
            renderedImage: null,
          },
        });
      }
    });
  };

  addToCard = () => {
    this.setState({
      creatingFinal: true,
    });

    const variantId = this.props.selection.variants.edges[0].node.id;
    const results = this.state.results?.result;

    // Get localStorage entry
    const ls = localStorage.getItem("edit-" + variantId)
      ? localStorage.getItem("edit-" + variantId)
      : localStorage.getItem(variantId);
    const parsedLs = ls ? JSON.parse(ls) : null;

    // Get massiveVoid id
    const uuid = parsedLs ? parsedLs.massiveVoid : uuidv4();

    // Version with blobs
    //this.props.addToCard(variantId, results, uuid);

    // Version with base64
    let bar = new Promise((resolve, reject) => {
      let resDetails = [];
      let counter = 0;

      results.forEach(async (element, e) => {
        await fetchAsBlob(element.renderedImage)
          .then(convertBlobToBase64)
          .then((resBase64) => {
            if (resBase64) {
              counter++;
            }

            resDetails = [
              ...resDetails,
              {
                image: resBase64,
                text: element.text,
              },
            ];
          });

        if (counter === this.props.count) {
          resolve(resDetails);
        }
      });
    });

    bar.then((resultDetailReturn) => {
      this.props.addToCard(
        variantId,
        resultDetailReturn,
        uuid,
        parsedLs ? parsedLs.pdfId : null,
        this.state["result-0"]
      );
    });
  };

  isFinished = () => {
    let count = 0;

    for (let index = 0; index < this.props.count; index++) {
      if (this.state["textarea-" + index] && this.state["save-" + index]) {
        count++;
      }
    }

    if (count === this.props.count) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { selection } = this.props;

    const isFinished = this.isFinished();

    return (
      <>
        <MDBEdgeHeader color="bg-pattern" className="sectionPage" />
        <div className="mt-3 mb-5">
          <MDBFreeBird>
            <MDBRow id="config">
              <MDBCol
                md="10"
                className="mx-auto float-none white py-2 px-2 border"
              >
                <MDBCardBody className="text-center">
                  {selection ? (
                    <>
                      <h2 className="h2-responsive mb-4">
                        <span className="font-weight-bold text-grey">
                          Plauderkiste
                        </span>{" "}
                        <span className="written text-blue">
                          {this.props.selection.title}
                        </span>
                      </h2>
                      {!this.state.creatingFinal &&
                      !this.state.isLoading &&
                      !this.state.loadingExisting &&
                      (this.state.successfulRendered !== 0 ||
                        !this.props.editData) ? (
                        <>
                          <div className="my-2">
                            <div className="custom-progress">
                              {Array.apply(null, {
                                length: this.props.count,
                              }).map((item, i) => {
                                return (
                                  <div
                                    className={
                                      i == this.state.slide - 1
                                        ? "custom-progress-item active"
                                        : i < this.state.slide
                                        ? this.state["save-" + i] &&
                                          this.state["textarea-" + i]
                                          ? "custom-progress-item active success"
                                          : "custom-progress-item active danger"
                                        : this.state["save-" + i] &&
                                          this.state["textarea-" + i]
                                        ? "custom-progress-item success"
                                        : "custom-progress-item"
                                    }
                                    key={"standard-" + i}
                                    onClick={() =>
                                      this.setState({ slide: i + 1 })
                                    }
                                  >
                                    <MDBIcon
                                      far
                                      icon={
                                        this.state["save-" + i] &&
                                        this.state["textarea-" + i]
                                          ? "check-circle"
                                          : "circle"
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </div>
                            {this.state.slide <= this.props.count && (
                              <p className="mb-0 text-muted mb-3">
                                Platte {this.state.slide} von {this.props.count}
                                .
                              </p>
                            )}
                          </div>
                          <div className="my-4 d-flex justify-content-between">
                            <MDBBtn
                              color="blue"
                              outline
                              disabled={this.state.slide === 1}
                              onClick={() =>
                                this.setState({ slide: this.state.slide - 1 })
                              }
                              className="d-md-inline-block d-none"
                            >
                              <MDBIcon
                                icon="angle-left"
                                className="mr-1 ml-0"
                              />
                              Zurück
                            </MDBBtn>
                            <div>
                              {this.state.slide <= this.props.count &&
                                this.state["save-" + [this.state.slide - 1]] &&
                                this.state[
                                  "textarea-" + [this.state.slide - 1]
                                ] &&
                                !localStorage.getItem(
                                  "edit-" +
                                    this.props.selection.variants.edges[0].node
                                      .id
                                ) && (
                                  <MDBBtn
                                    color="green"
                                    outline
                                    onClick={() => {
                                      this.saveDraft();
                                    }}
                                  >
                                    <MDBIcon far icon="check-circle" />
                                    Entwurf speichern
                                  </MDBBtn>
                                )}
                              {this.state.successfulRendered ===
                                this.props.count &&
                                this.state.slide !== this.props.count + 1 && (
                                  <MDBBtn
                                    color="blue"
                                    outline
                                    disabled={!isFinished}
                                    onClick={() => {
                                      this.renderPreview();
                                      this.setState({
                                        slide: this.props.count + 1,
                                      });
                                    }}
                                  >
                                    <MDBIcon far icon="eye" />
                                    Zusammenfassung
                                  </MDBBtn>
                                )}
                              <>
                                {this.state.slide <= this.props.count && (
                                  <>
                                    {this.state.slide !==
                                    this.props.count + 1 ? (
                                      <MDBBtn
                                        color="blue"
                                        className="d-md-inline-block d-none"
                                        disabled={
                                          this.state.slide > 9 ||
                                          (this.state.slide > 8 && !isFinished)
                                        }
                                        onClick={
                                          this.state.slide > 8
                                            ? () => {
                                                this.renderPreview();
                                                this.setState(
                                                  {
                                                    slide: this.props.count + 1,
                                                  },
                                                  () => window.scrollTo(0, 0)
                                                );
                                              }
                                            : () =>
                                                this.setState(
                                                  {
                                                    slide: this.state.slide + 1,
                                                  },
                                                  () => window.scrollTo(0, 0)
                                                )
                                        }
                                      >
                                        {this.state.slide > 8
                                          ? "Zusammenfassung"
                                          : "Weiter"}
                                        <MDBIcon
                                          icon="angle-right"
                                          className="mr-0 ml-1"
                                        />
                                      </MDBBtn>
                                    ) : (
                                      <MDBBtn
                                        color="blue"
                                        outline
                                        onClick={() =>
                                          this.setState({ slide: 1 })
                                        }
                                      >
                                        <MDBIcon icon="expand" />
                                        Bearbeiten
                                      </MDBBtn>
                                    )}
                                  </>
                                )}
                              </>
                            </div>
                          </div>
                          {this.state.successfulRendered > 1 &&
                          this.state.slide === this.props.count + 1 ? (
                            <div>
                              <h2 className="mb-2">Zusammenfassung</h2>
                              <MDBRow className="preview">
                                {Array.apply(null, {
                                  length: this.props.count,
                                }).map((item, i) => {
                                  return (
                                    <React.Fragment key={"preview-" + i}>
                                      <MDBCol
                                        lg="3"
                                        onClick={() =>
                                          this.setState({ slide: i + 1 })
                                        }
                                      >
                                        <MDBView>
                                          <img
                                            src={
                                              this.state["result-" + i]
                                                ?.renderedImage
                                            }
                                            alt="Bildvorschau"
                                            className="img-fluid"
                                          />
                                          <MDBMask className="clickable" />
                                        </MDBView>
                                      </MDBCol>
                                      <MDBCol lg="3">
                                        <div className="preview-text">
                                          <MDBView>
                                            <img
                                              src={require("../../../../assets/blank.jpg")}
                                              alt="Blank Plauderkiste"
                                              className="img-fluid"
                                            />
                                            <MDBMask className="d-flex flex-center">
                                              <div className="text-container">
                                                <span>
                                                  {
                                                    this.state["result-" + i]
                                                      ?.text
                                                  }
                                                </span>
                                              </div>
                                            </MDBMask>
                                          </MDBView>
                                        </div>
                                      </MDBCol>
                                    </React.Fragment>
                                  );
                                })}
                              </MDBRow>
                              <div>
                                <MDBBtn
                                  color="blue"
                                  outline
                                  onClick={this.addToCard}
                                  className="mt-4 d-block mx-auto"
                                  disabled={this.state.doingSave}
                                  size="lg"
                                >
                                  {this.state.doingSave && (
                                    <MDBIcon
                                      icon="spinner"
                                      className="fa-spin m-0 p-0"
                                    />
                                  )}
                                  {"  "}
                                  {localStorage.getItem(
                                    "edit-" +
                                      this.props.selection.variants.edges[0]
                                        .node.id
                                  )
                                    ? "Änderungen übernehmen"
                                    : "In den Warenkorb"}
                                </MDBBtn>
                                {this.state.doingSave && (
                                  <span className="text-muted small">
                                    Wir optimieren die Bilder. Dies kann je nach
                                    Auflösung einige Zeit beanspruchen.
                                  </span>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="config">
                              {this.state.slide <= this.props.count ? (
                                <>
                                  {Array.apply(null, {
                                    length: this.props.count + 1,
                                  }).map((item, i) => {
                                    if (this.state.slide === i) {
                                      return (
                                        <MDBRow
                                          className="config-item"
                                          style={styleFadeIn}
                                          key={"slide-" + i}
                                        >
                                          <MDBCol
                                            lg="6"
                                            className="text-center head font-weight-bold lead mb-2"
                                          >
                                            Vorderseite
                                          </MDBCol>
                                          <MDBCol
                                            lg="6"
                                            className="text-center head font-weight-bold lead mb-2 d-none d-lg-block"
                                          >
                                            Rückseite
                                          </MDBCol>
                                          <MDBCol lg="6">
                                            <ImageSelector
                                              saveImage={this.saveImage}
                                              savedState={
                                                this.state["save-" + (i - 1)]
                                                  ? this.state[
                                                      "save-" + (i - 1)
                                                    ]
                                                  : undefined
                                              }
                                              removeImage={this.removeImage}
                                              id={i - 1}
                                            />
                                          </MDBCol>
                                          <MDBCol
                                            lg="6"
                                            className="text-center head font-weight-bold lead mb-2 d-lg-none d-block mt-3"
                                          >
                                            Rückseite
                                          </MDBCol>
                                          <MDBCol lg="6" className="example">
                                            <TextSelector
                                              value={
                                                this.state[
                                                  "textarea-" + (i - 1)
                                                ]
                                                  ? this.state[
                                                      "textarea-" + (i - 1)
                                                    ]
                                                  : ""
                                              }
                                              getValue={(val) =>
                                                this.setState({
                                                  ["textarea-" + (i - 1)]: val,
                                                })
                                              }
                                            />
                                          </MDBCol>
                                        </MDBRow>
                                      );
                                    }
                                  })}
                                  <div className="my-4 d-flex d-lg-none justify-content-between">
                                    <MDBBtn
                                      color="blue"
                                      outline
                                      disabled={this.state.slide === 1}
                                      onClick={() =>
                                        this.setState({
                                          slide: this.state.slide - 1,
                                        })
                                      }
                                    >
                                      <MDBIcon
                                        icon="angle-left"
                                        className="mr-1 ml-0"
                                      />
                                      Zurück
                                    </MDBBtn>
                                    <div>
                                      {this.state.successfulRendered ===
                                        this.props.count &&
                                        this.state.slide !==
                                          this.props.count + 1 && (
                                          <MDBBtn
                                            color="blue"
                                            outline
                                            disabled={!isFinished}
                                            onClick={() => {
                                              this.renderPreview();
                                              this.setState({
                                                slide: this.props.count + 1,
                                              });
                                            }}
                                          >
                                            <MDBIcon far icon="eye" />
                                            Zusammenfassung
                                          </MDBBtn>
                                        )}
                                      {this.state.successfulRendered !==
                                        this.props.count ||
                                      this.state.slide !==
                                        this.props.count + 1 ? (
                                        <MDBBtn
                                          color="blue"
                                          disabled={
                                            this.state.slide > 9 ||
                                            (this.state.slide > 8 &&
                                              !isFinished)
                                          }
                                          onClick={
                                            this.state.slide > 8
                                              ? () => {
                                                  this.renderPreview();
                                                  this.setState(
                                                    {
                                                      slide:
                                                        this.props.count + 1,
                                                    },
                                                    () => window.scrollTo(0, 0)
                                                  );
                                                }
                                              : () =>
                                                  this.setState(
                                                    {
                                                      slide:
                                                        this.state.slide + 1,
                                                    },
                                                    () => window.scrollTo(0, 0)
                                                  )
                                          }
                                        >
                                          {this.state.slide > 8
                                            ? "Zusammenfassung"
                                            : "Weiter"}
                                          <MDBIcon
                                            icon="angle-right"
                                            className="mr-0 ml-1"
                                          />
                                        </MDBBtn>
                                      ) : (
                                        <MDBBtn
                                          color="blue"
                                          outline
                                          onClick={() =>
                                            this.setState({ slide: 1 })
                                          }
                                        >
                                          <MDBIcon icon="expand" />
                                          Bearbeiten
                                        </MDBBtn>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <MDBRow className="flex-center mt-4">
                                    <MDBCol lg="6">
                                      <MDBProgress material preloader />
                                    </MDBCol>
                                  </MDBRow>
                                  <p className="blue-text mb-2 lead">
                                    Wir erstellen Deine Vorschau.
                                  </p>
                                  <p className="text-muted small">
                                    Bitte schließe diese Seite nicht.
                                  </p>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className="mt-5">
                          <MDBRow className="flex-center mt-4">
                            <MDBCol lg="6">
                              {this.state.fetchError ? (
                                <MDBIcon
                                  icon="times-circle"
                                  size="lg"
                                  className="text-danger"
                                />
                              ) : (
                                <MDBProgress material preloader />
                              )}
                            </MDBCol>
                          </MDBRow>
                          {this.state.creatingFinal && (
                            <div>
                              <p className="blue-text mb-2 lead">
                                Die Plauderkiste wird generiert.
                              </p>
                              <p className="text-muted small">
                                Bitte schließe diese Seite nicht.
                              </p>
                            </div>
                          )}
                          {this.state.loadingExisting && (
                            <div>
                              <p className="blue-text mb-2 lead">
                                Deine Plauderkiste wird geladen.
                              </p>
                              <p className="text-muted small">
                                Bitte schließe diese Seite nicht.
                              </p>
                            </div>
                          )}
                          {this.state.isLoading && (
                            <div>
                              <p className="blue-text mb-2 lead">
                                Der Entwurf wird gespeichert.
                              </p>
                              <p className="text-muted small">
                                Bitte schließe diese Seite nicht.
                              </p>
                            </div>
                          )}
                          {this.props.editData && !isFinished && (
                            <div>
                              {this.state.fetchError ? (
                                <>
                                  <p className="text-danger mb-2 lead">
                                    Die Plauderkiste konnte nicht geladen
                                    werden.
                                  </p>
                                  <p className="text-muted small">
                                    Bitte versuche es später erneut oder
                                    erstelle die Plauderkiste erneut.
                                  </p>
                                  <MDBBtn color="blue" outline href="/cart">
                                    Zurück zum Warenkorb
                                  </MDBBtn>
                                </>
                              ) : (
                                <>
                                  <p className="blue-text mb-2 lead">
                                    Die Plauderkiste wird geladen.
                                  </p>
                                  <p className="text-muted small">
                                    Bitte schließe diese Seite nicht.
                                  </p>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <MDBSpinner blue />
                  )}
                </MDBCardBody>
              </MDBCol>
            </MDBRow>
          </MDBFreeBird>
        </div>
        <ToastContainer newestOnTop={true} autoClose={4000} />
      </>
    );
  }
}
//#endregion

//#region > Exports
export default Standard;
//#endregion
/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
