//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// Router
import { withRouter } from "react-router-dom";
// PropTypes
import PropTypes from "prop-types";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBIcon, MDBBtn, MDBView, MDBMask, MDBProgress } from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

// Actions
import {
  updateLineItemInCart,
  removeLineItemInCart,
} from "../../../store/actions/shopifyActions";
//> CSS
import "./lineitem.scss";
//#endregion

//#region > Components
class LineItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loading: false };
  }

  componentDidUpdate = (prevProps) => {
    this.state.loading &&
      JSON.stringify(this.props.checkout.lineItems) !==
        JSON.stringify(prevProps.checkout.lineItems) &&
      this.setState({ loading: false });
  };

  decrementQuantity(lineItemId, currentItem, allItems, variantId) {
    this.setState({ loading: true }, () => {
      const otherItems = allItems.filter(
        (item) => item.massiveVoid !== currentItem.massiveVoid
      );

      const newCurrentItem = currentItem;

      newCurrentItem.amount = currentItem.amount - 1;

      localStorage.setItem(
        "inCart-" + variantId,
        JSON.stringify([...otherItems, newCurrentItem])
      );

      this.props.updateLineItemInCart(
        lineItemId,
        this.props.lineItem.quantity - 1,
        this.props.checkout.id
      );
    });
  }

  incrementQuantity(lineItemId, currentItem, allItems, variantId) {
    this.setState({ loading: true }, () => {
      const otherItems = allItems.filter(
        (item) => item.massiveVoid !== currentItem.massiveVoid
      );

      const newCurrentItem = currentItem;

      newCurrentItem.amount = currentItem.amount + 1;

      localStorage.setItem(
        "inCart-" + variantId,
        JSON.stringify([...otherItems, newCurrentItem])
      );

      this.props.updateLineItemInCart(
        lineItemId,
        this.props.lineItem.quantity + 1,
        this.props.checkout.id
      );
    });
  }

  removeItem(id, massiveVoid, allItems, variantId) {
    if (allItems.length > 1) {
      const otherItems = allItems.filter(
        (item) => item.massiveVoid !== massiveVoid
      );

      if (otherItems) {
        localStorage.setItem("inCart-" + variantId, JSON.stringify(otherItems));
        this.setState({
          loading: false,
        });
      } else {
        this.setState({ loading: true, removed: true }, () => {
          this.props.removeLineItemInCart(id, this.props.checkout.id);
          localStorage.removeItem("inCart-" + variantId);
        });
      }
    } else {
      this.setState({ loading: true, removed: true }, () => {
        this.props.removeLineItemInCart(id, this.props.checkout.id);
        localStorage.removeItem("inCart-" + variantId);
      });
    }
  }

  stringifyTitle = (title) => {
    return title
      .replace(/[^a-zA-Z ]/g, "")
      ?.toLowerCase()
      ?.trim()
      ?.replace(new RegExp(" ", "g"), "-");
  };

  edit = (variantId, massiveVoid, pdfId) => {
    localStorage.setItem(
      "edit-" + variantId,
      JSON.stringify({
        variantId: variantId,
        massiveVoid: massiveVoid,
        pdfId: pdfId,
      })
    );

    this.props.history.push(this.getLink(variantId));
  };

  getLink = (id) => {
    switch (id) {
      case "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMzMDI4OTI4NzM1Mg==":
        return "standard";
      case "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMzMDI5ODcyNDUzNg==":
        return "bilderwelt";
      case "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTMzMDMxODQxODEwNA==":
        return "puzzle";
      default:
        return null;
    }
  };

  render() {
    const variantId = this.props.lineItem?.variant?.id;
    const inCartLS = localStorage.getItem("inCart-" + variantId);

    if (inCartLS) {
      let inCartLSData = JSON.parse(inCartLS);

      inCartLSData.sort((a, b) => (a.created > b.created ? 1 : -1));

      return (
        <>
          {inCartLSData.map((item, i) => {
            return (
              <li
                className={
                  this.state.removed
                    ? "line-item mt-2 pb-2 position-relative move"
                    : "line-item mt-2 pb-2 position-relative"
                }
              >
                {this.state.loading && false && (
                  <MDBProgress
                    material
                    preloader
                    className="position-absolute"
                  />
                )}
                <div className="d-block d-lg-none">
                  <div className="flex-center mt-4">
                    {this.props.lineItem.variant.image && (
                      <MDBView>
                        <img
                          src={this.props.lineItem.variant.image.src}
                          className="img-fluid"
                          alt={`${this.props.lineItem.title} product shot`}
                        />
                        <MDBMask>
                          <img
                            src={item.preview}
                            className="img-fluid preview"
                            alt="Overlay"
                          />
                        </MDBMask>
                      </MDBView>
                    )}
                  </div>
                  <div className="my-2 text-center">
                    <p className="font-weight-bold mb-0 lead">
                      {this.props.lineItem.title}
                    </p>
                    <small className="text-muted">
                      Mit Deinen individuellen Inhalten.
                    </small>
                    <p className="mx-3 font-weight-bold mb-1">
                      Anzahl: {this.props.lineItem.quantity}
                    </p>
                  </div>
                  <div className="d-block">
                    <div className="d-flex justify-content-center mb-3">
                      <MDBBtn
                        color="blue"
                        outline
                        size="md"
                        disabled={this.state.loading || item.amount <= 1}
                        onClick={() =>
                          this.decrementQuantity(
                            this.props.lineItem.id,
                            item,
                            inCartLSData,
                            variantId
                          )
                        }
                      >
                        <MDBIcon icon="minus" className="m-0 p-0" />
                      </MDBBtn>
                      <MDBBtn
                        color="blue"
                        outline
                        size="md"
                        disabled={this.state.loading}
                        onClick={() =>
                          this.incrementQuantity(
                            this.props.lineItem.id,
                            item,
                            inCartLSData,
                            variantId
                          )
                        }
                        className="ml-2"
                      >
                        <MDBIcon icon="plus" className="m-0 p-0" />
                      </MDBBtn>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <MDBBtn
                        color="red"
                        outline
                        size="md"
                        disabled={this.state.loading}
                        onClick={() => {
                          this.removeItem(
                            this.props.lineItem.id,
                            item.massiveVoid,
                            inCartLSData,
                            variantId
                          );
                        }}
                      >
                        <MDBIcon icon="trash" />
                        Entfernen
                      </MDBBtn>
                      <MDBBtn
                        color="blue"
                        outline
                        size="md"
                        disabled={this.state.loading}
                        onClick={() =>
                          this.edit(variantId, item.massiveVoid, item.pdfId)
                        }
                        className="ml-2"
                      >
                        <MDBIcon icon="expand" />
                        Bearbeiten
                      </MDBBtn>
                    </div>
                  </div>
                </div>
                <div className="d-none d-lg-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="d-inline-block ">
                      {this.props.lineItem.variant.image && (
                        <MDBView>
                          <img
                            src={this.props.lineItem.variant.image.src}
                            className="img-fluid"
                            alt={`${this.props.lineItem.title} product shot`}
                          />
                          <MDBMask>
                            <img
                              src={item.preview}
                              className="img-fluid preview"
                              alt="Overlay"
                            />
                          </MDBMask>
                        </MDBView>
                      )}
                    </div>
                    <div className="d-inline-block ml-lg-4 ml-0">
                      <p className="font-weight-bold mb-0">
                        {this.props.lineItem.title}
                      </p>
                      <small className="text-muted">
                        Mit Deinen individuellen Inhalten.
                      </small>
                      <div className="d-lg-none d-block mt-2 mb-3">
                        <MDBBtn
                          color="special"
                          outline
                          size="md"
                          disabled={this.state.loading}
                          onClick={() =>
                            this.decrementQuantity(
                              this.props.lineItem.id,
                              item,
                              inCartLSData,
                              variantId
                            )
                          }
                        >
                          <MDBIcon icon="minus" className="m-0" />
                        </MDBBtn>
                        <span className="mx-3 lead font-weight-bold">
                          {this.props.lineItem.quantity}
                        </span>
                        <MDBBtn
                          color="special"
                          outline
                          size="md"
                          disabled={this.state.loading}
                          onClick={() =>
                            this.incrementQuantity(
                              this.props.lineItem.id,
                              item,
                              inCartLSData,
                              variantId
                            )
                          }
                        >
                          <MDBIcon icon="plus" className="m-0" />
                        </MDBBtn>
                      </div>
                      <div className="mt-2">
                        <MDBBtn
                          color="red"
                          outline
                          size="md"
                          disabled={this.state.loading}
                          onClick={() => {
                            this.removeItem(
                              this.props.lineItem.id,
                              item.massiveVoid,
                              inCartLSData,
                              variantId
                            );
                          }}
                        >
                          <MDBIcon icon="times" />
                          Entfernen
                        </MDBBtn>
                        <MDBBtn
                          color="blue"
                          outline
                          size="md"
                          disabled={this.state.loading}
                          onClick={() =>
                            this.edit(variantId, item.massiveVoid, item.pdfId)
                          }
                          className="ml-2"
                        >
                          <MDBIcon icon="expand" />
                          Bearbeiten
                        </MDBBtn>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="text-right mr-5 d-flex align-items-center">
                      <p className="lead font-weight-bold m-0">
                        {formatter.format(
                          item.amount * this.props.lineItem.variant.price
                        )}{" "}
                        €
                      </p>
                    </div>
                    <div className="d-none d-lg-flex align-items-center">
                      <MDBBtn
                        color="blue"
                        outline
                        size="md"
                        disabled={this.state.loading || item.amount <= 1}
                        onClick={() =>
                          this.decrementQuantity(
                            this.props.lineItem.id,
                            item,
                            inCartLSData,
                            variantId
                          )
                        }
                      >
                        <MDBIcon icon="minus" className="m-0 p-0" />
                      </MDBBtn>
                      <span className="mx-3 lead font-weight-bold">
                        {item.amount}
                      </span>
                      <MDBBtn
                        color="blue"
                        outline
                        size="md"
                        disabled={this.state.loading}
                        onClick={() =>
                          this.incrementQuantity(
                            this.props.lineItem.id,
                            item,
                            inCartLSData,
                            variantId
                          )
                        }
                      >
                        <MDBIcon icon="plus" className="m-0 p-0" />
                      </MDBBtn>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </>
      );
    } else {
      return null;
    }
  }
}
//#endregion

//#region > PropTypes
LineItem.propTypes = {
  lineItem: PropTypes.object.isRequired,
};
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return {
    checkout: state.shop.checkout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLineItemInCart: (lineitemid, quantity, checkoutid) =>
      dispatch(updateLineItemInCart(lineitemid, quantity, checkoutid)),
    removeLineItemInCart: (lineitemid, checkoutid) =>
      dispatch(removeLineItemInCart(lineitemid, checkoutid)),
  };
};

//> Number formatting
const formatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LineItem));
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
