//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// Router
import { Link, withRouter } from "react-router-dom";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBBtn,
  MDBRow,
  MDBCol,
} from "mdbreact";

//> Icons
import { ReactComponent as CartSvg } from "../../../assets/cartIcon.svg";
//#endregion

//#region > Components
class Navbar extends React.Component {
  state = {
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  closeCollapse = (collapseID) => () => {
    window.scrollTo(0, 0);

    this.state.collapseID === collapseID && this.setState({ collapseID: "" });
  };

  render() {
    const overlay = (
      <div
        id="sidenav-overlay"
        style={{ backgroundColor: "transparent" }}
        onClick={this.toggleCollapse("mainNavbarCollapse")}
      />
    );
    const { collapseID } = this.state;

    return (
      <div>
        <MDBNavbar color="white" light>
          <MDBContainer>
            <MDBRow className="w-100 d-lg-flex d-none">
              <MDBCol lg="4" className="align-items-center d-flex">
                <Link to="/">
                  <MDBBtn color="blue">
                    <MDBIcon icon="angle-left" size="lg" className="mr-1" />
                    Produkte
                  </MDBBtn>
                </Link>
              </MDBCol>
              <MDBCol
                lg="4"
                className="d-flex align-items-center justify-content-center"
              >
                <a href="https://www.plauderkiste.com">
                  <img
                    src={require("../../../assets/logo.png")}
                    style={{ height: "60px" }}
                    alt="Plauderkiste Logo"
                  />
                </a>
              </MDBCol>
              <MDBCol
                lg="4"
                className="d-flex align-items-center justify-content-end"
              >
                <Link to="/cart" className="text-dark carticon">
                  <CartSvg />
                </Link>
              </MDBCol>
            </MDBRow>
            <div className="justify-content-between d-lg-none d-flex align-items-center w-100">
              <div>
                <Link to="/">
                  <img
                    src={require("../../../assets/logo.png")}
                    style={{ height: "60px" }}
                    alt="Plauderkiste Logo"
                  />
                </Link>
              </div>
              <div>
                <Link to="/cart" className="text-dark carticon">
                  <CartSvg />
                </Link>
              </div>
            </div>
          </MDBContainer>
        </MDBNavbar>
        {collapseID && overlay}
      </div>
    );
  }
}
//#endregion

//#region > Exports
export default withRouter(Navbar);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
