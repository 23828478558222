//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { Link } from "react-router-dom";
//> Redux
// Connect
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBContainer,
  MDBCol,
  MDBRangeInput,
  MDBRow,
  MDBIcon,
  MDBAlert,
  MDBBtn,
  MDBView,
  MDBMask,
} from "mdbreact";

//> Components
import { ShopSectionPlaceholder } from "../../molecules";
//#endregion

//#region > Config
const DESC = [
  {
    slug: "standard",
    text: "9 Bilder, 9 Texte",
  },
  {
    slug: "bilderwelt",
    text: "18 Bilder",
  },
  {
    slug: "puzzle",
    text: "9 Bilder, 9 Texte + Puzzlebild",
  },
];
//#endregion

//#region > Components
class Selector extends React.Component {
  getLink = (id) => {
    switch (id) {
      case "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY1NTg5MzAyMDY5MDQ=":
        return "standard";
      case "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY1NTg5MzEyNTU0ODA=":
        return "bilderwelt";
      case "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY1NTg5MzI1OTg5Njg=":
        return "puzzle";
      default:
        return null;
    }
  };

  render() {
    const { products } = this.props;

    return (
      <MDBContainer className="text-center pt-5 my-5">
        <div className="line" />
        <h2 className="h2-responsive mt-3 mb-4">
          <span className="font-weight-bold text-grey">Unsere</span>{" "}
          <span className="written">individuellen</span>{" "}
          <span className="font-weight-bold text-grey">Varianten</span>
        </h2>
        <MDBRow>
          {products ? (
            products.map((product, p) => {
              const info = product.node;
              const image = info.images.edges[0].node.transformedSrc
                ? info.images.edges[0].node.transformedSrc
                : info.images.edges[0].node.originalSrc;
              const desc = DESC.filter(
                (item) => item.slug === info.title.toLowerCase()
              )[0];

              return (
                <MDBCol lg="4" md="6" className="product-item">
                  <Link to={() => this.getLink(info.id)}>
                    <div className="product-item-container py-3 text-dark clickable">
                      <MDBView>
                        <img
                          src={image}
                          alt={info.title}
                          className="img-fluid w-75 mx-auto"
                        />
                        <MDBMask className="clickable" />
                      </MDBView>
                      <p className="font-weight-bold lead mt-2 mb-0">
                        {info.title}
                      </p>
                      <p className="small">{desc?.text}</p>
                      <p className="mb-0">
                        EUR {info.variants.edges[0].node.price}
                      </p>
                      <p className="text-muted small">inkl. MwSt.</p>
                      <Link to={() => this.getLink(info.id)}>
                        <MDBBtn color="blue">
                          <MDBIcon icon="expand" />
                          Jetzt konfigurieren
                        </MDBBtn>
                      </Link>
                    </div>
                  </Link>
                </MDBCol>
              );
            })
          ) : (
            <ShopSectionPlaceholder />
          )}
        </MDBRow>
      </MDBContainer>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return {
    products: state?.shop?.payload,
    shopData: state?.shop,
  };
};
//#endregion

//#region > Exports
export default connect(mapStateToProps)(Selector);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
