// Have initial state for when state is not ready to be passed
const initState = {
  payload: null,
  inventory: null,
  error: undefined,
  checkout: null,
  createdOrder: null,
};

const shopifyReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_PRODUCTS_SUCCESS":
      return {
        ...state,
        payload: action.payload?.data,
        error: false,
      };
    case "GET_PRODUCTS_FAIL":
      console.error("Failed to fetch shopify products", action.payload.error);

      return {
        ...state,
        payload: null,
        error: action.payload.error,
      };
    case "GETINVENTORY_SUCCESS":
      return {
        ...state,
        inventory: action.payload.data,
        error: false,
      };
    case "GETINVENTORY_ERROR":
      return {
        ...state,
        error: action.payload.error,
        inventory: null,
      };
    case "CREATECHECKOUT_SUCCESS":
      return {
        ...state,
        checkout: action.payload.data,
        error: false,
      };
    case "ADDVARIANT_SUCCESS":
      return {
        ...state,
        checkout: action.payload.data,
        error: false,
      };
    case "UPDATELINEITEM_SUCCESS":
      return {
        ...state,
        checkout: action.payload.data,
        error: false,
      };
    case "REMOVELINEITEM_SUCCESS":
      return {
        ...state,
        checkout: action.payload.data,
        error: false,
      };
    default:
      return state;
  }
};

export default shopifyReducer;

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
