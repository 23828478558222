//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// Router
import { Link, withRouter } from "react-router-dom";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBContainer,
  MDBMask,
  MDBView,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBAlert,
  MDBProgress,
  MDBSpinner,
} from "mdbreact";
//> Redux
// Connect
import { connect } from "react-redux";

//> CSS
import "./style.scss";
//> Component
import { LineItem } from "../../atoms";
//> Images
import { ReactComponent as EmptyCartImg } from "../../../assets/content/emptycart.svg";
// Actions
import { getProducts } from "../../../store/actions/shopifyActions";
//#endregion

//#region > Data
const freeShipping = 60;
//#endregion

//#region > Components
class CartPage extends React.Component {
  state = { selection: undefined };

  openCheckout = () => {
    const checkoutURL = this.props.checkout.webUrl;

    window.location.replace(checkoutURL);
  };

  render() {
    if (this.props.checkout) {
      const lineItems = this.props.checkout.lineItems.edges;

      return (
        <MDBContainer id="cart">
          <h1 className="text-center">Dein Warenkorb</h1>
          {this.state.loading && this.props.showLoading && (
            <div className="slider">
              <div className="line"></div>
              <div className="subline inc"></div>
              <div className="subline dec"></div>
            </div>
          )}
          {lineItems && lineItems.length > 0 && (
            <div className="p-3 bg-white my-4">
              <MDBAlert color="success">
                <MDBIcon icon="check-circle" className="mr-2" />
                Gute Wahl! Hier deine Produkte:
              </MDBAlert>
              {lineItems.map((item) => {
                return (
                  <LineItem
                    key={item.node.id.toString()}
                    lineItem={item.node}
                  />
                );
              })}
            </div>
          )}
          {lineItems && lineItems.length < 1 && (
            <>
              <div className="py-5">
                <MDBRow className="d-flex align-items-center text-center">
                  <MDBCol lg="6">
                    <p className="h4-responsive font-weight-bold mb-1">
                      Noch keine Plauderkisten im Warenkorb.
                    </p>
                    <div className="mb-3">
                      <Link to="/">
                        <MDBBtn color="blue" size="lg">
                          Jetzt erkunden
                        </MDBBtn>
                      </Link>
                    </div>
                  </MDBCol>
                  <MDBCol lg="6">
                    <MDBView>
                      <EmptyCartImg className="img-fluid" />
                      <MDBMask></MDBMask>
                    </MDBView>
                  </MDBCol>
                </MDBRow>
              </div>
            </>
          )}
          <MDBRow className="mb-4">
            <MDBCol lg="6">
              <div className="p-3 bg-white h-100 d-flex align-items-center">
                <div className="w-100">
                  <div className="d-flex justify-content-between">
                    <span>Zwischensumme</span>
                    <span>
                      {formatter.format(this.props.checkout.subtotalPrice)} €
                    </span>
                  </div>
                  <small className="text-muted">inkl. MwSt.</small>
                  <small className="text-muted d-block">
                    zzgl. Lieferkosten
                  </small>
                  <div className="d-flex justify-content-between font-weight-bold mt-2">
                    <span>Gesamt</span>
                    <span>
                      {formatter.format(this.props.checkout.totalPrice)} €
                    </span>
                  </div>
                </div>
              </div>
            </MDBCol>
            <MDBCol lg="6">
              <div className="p-3 bg-white h-100 d-flex align-items-center">
                <div className="w-100">
                  <div className="text-left pl-4 custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="defaultUnchecked"
                      checked={this.state.agb}
                      onChange={(e) => {
                        this.setState({
                          agb: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="defaultUnchecked"
                    >
                      <p className="mb-0">
                        Ich bestätige die{" "}
                        <a
                          href="https://plauderkiste.com/policies/terms-of-service"
                          target="_blank"
                        >
                          AGB
                        </a>{" "}
                        gelesen und akzeptiert zu haben.
                      </p>
                    </label>
                  </div>
                  <div className="text-left pl-4 custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="defaultUncheckedTos"
                      checked={this.state.tos}
                      onChange={(e) => {
                        this.setState({
                          tos: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="defaultUncheckedTos"
                    >
                      <p className="mb-0">
                        Ich akzeptiere die{" "}
                        <a
                          href="https://plauderkiste.com/policies/refund-policy"
                          target="_blank"
                        >
                          Widerrufsbelehrung
                        </a>
                        .
                      </p>
                    </label>
                  </div>
                  <p className="text-muted small mt-2">
                    Ich akzeptiere die{" "}
                    <a
                      href="https://plauderkiste.com/policies/privacy-policy"
                      target="_blank"
                    >
                      Datenschutzerklärung
                    </a>
                    .
                  </p>
                  <div className="text-right">
                    <MDBBtn
                      color="success"
                      size="lg"
                      onClick={() => {
                        this.openCheckout();
                      }}
                      disabled={
                        lineItems.length < 1 ||
                        !this.state.agb ||
                        !this.state.tos
                      }
                    >
                      <MDBIcon icon="lock" className="pr-2" />
                      Zur Kasse
                    </MDBBtn>
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      );
    } else {
      return (
        <div className="py-5 text-center">
          <MDBSpinner />
        </div>
      );
    }
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return {
    checkout: state.shop.checkout,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProducts: () => dispatch(getProducts()),
  };
};

//> Number formatting
const formatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
//#endregion

//#region > Exports
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CartPage));
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
