//#region > Imports
//> Queries
import {
  GET_PRODUCTS,
  GET_CHECKOUT,
  checkoutLineItemsAdd,
  createCheckout,
  checkoutLineItemsUpdate,
  checkoutLineItemsRemove,
} from "../../queries";
//#endregion

//#region > Functions
export const getCheckout = (id) => {
  return (dispatch, getState, { clientShopify }) => {
    const checkoutId = id ? id : getState()?.shop?.checkout?.id;

    if (checkoutId) {
      return clientShopify
        .mutate({
          mutation: GET_CHECKOUT,
          variables: {
            checkoutId: checkoutId,
          },
        })
        .then(({ data }) => {
          const isFinished = data.node.completedAt ? true : false;

          if (isFinished) {
            saveCheckout();

            // Remove inCart items
            for (let key in localStorage) {
              if (key.includes("inCart-")) {
                localStorage.removeItem(key);
              }
            }

            window.location.reload();

            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          console.warn(err);

          // Let's say it's finished to create a new one
          return true;
        });
    } else {
      // Create checkout
      return true;
    }
  };
};

// Initializes shopify checkout
export const initShopify = (getNew) => {
  return async (dispatch, getState, { clientShopify, getFirebase }) => {
    // Get saved checkout in local storage
    const checkout = JSON.parse(localStorage.getItem("checkout"));

    const checkoutIsFinished = await dispatch(getCheckout(checkout?.id));

    // Check if logged in
    if (!checkout || getNew || checkoutIsFinished) {
      // Create Shopify checkout
      clientShopify
        .mutate({
          mutation: createCheckout,
          variables: {
            input: {},
          },
        })
        .then((res) => {
          saveCheckout(res.data.checkoutCreate.checkout);

          if (res.data.checkoutCreate.checkout) {
            dispatch({
              type: "CREATECHECKOUT_SUCCESS",
              payload: { data: res.data.checkoutCreate.checkout },
            });
          } else {
            // Create checkout without shipping information
            clientShopify
              .mutate({
                mutation: createCheckout,
                variables: {
                  input: {},
                },
              })
              .then((res) => {
                saveCheckout(res.data.checkoutCreate.checkout);

                dispatch({
                  type: "CREATECHECKOUT_SUCCESS",
                  payload: { data: res.data.checkoutCreate.checkout },
                });
              });
          }
        });
    } else {
      dispatch({
        type: "CREATECHECKOUT_SUCCESS",
        payload: { data: checkout },
      });
    }
  };
};

// Get all products
export const getProducts = () => {
  return (dispatch, getState, { clientShopify }) => {
    clientShopify
      .query({
        query: GET_PRODUCTS,
      })
      .then(({ data }) => {
        dispatch({
          type: "GET_PRODUCTS_SUCCESS",
          payload: {
            data: data?.products?.edges,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "GET_PRODUCTS_FAIL",
          payload: {
            errorCode: 680,
            message: "Failed to fetch products ",
            error: err,
          },
        });
      });
  };
};

// Adds amount of a variant to a checkout
export const addVariantToCart = (variantId, quantity, checkoutId) => {
  return async (dispatch, getState, { clientShopify }) => {
    const checkoutIsFinished = await dispatch(getCheckout(checkoutId));

    if (!checkoutIsFinished) {
      clientShopify
        .mutate({
          mutation: checkoutLineItemsAdd,
          variables: {
            checkoutId: checkoutId,
            lineItems: [
              {
                variantId,
                quantity: parseInt(quantity, 10),
              },
            ],
          },
        })
        .then((res) => {
          const checkout = res.data.checkoutLineItemsAdd.checkout;

          saveCheckout(checkout);

          dispatch({
            type: "ADDVARIANT_SUCCESS",
            payload: { data: checkout },
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
};

// Adds multiple line items to cart
export const addMultipleToCart = (checkoutId, lineItems) => {
  return async (dispatch, getState, { clientShopify }) => {
    const checkoutIsFinished = await dispatch(getCheckout(checkoutId));

    if (!checkoutIsFinished) {
      clientShopify
        .mutate({
          mutation: checkoutLineItemsAdd,
          variables: {
            checkoutId: checkoutId,
            lineItems,
          },
        })
        .then((res) => {
          const checkout = res.data.checkoutLineItemsAdd.checkout;

          saveCheckout(checkout);

          dispatch({
            type: "ADDVARIANT_SUCCESS",
            payload: { data: checkout },
          });
        });
    }
  };
};

// Updates amount of a specific line item in the checkout
export const updateLineItemInCart = (lineItemId, quantity, checkoutId) => {
  return async (dispatch, getState, { clientShopify }) => {
    const checkoutIsFinished = await dispatch(getCheckout(checkoutId));

    if (!checkoutIsFinished) {
      clientShopify
        .mutate({
          mutation: checkoutLineItemsUpdate,
          variables: {
            checkoutId: checkoutId,
            lineItems: [
              {
                id: lineItemId,
                quantity: parseInt(quantity, 10),
              },
            ],
          },
        })
        .then((res) => {
          const checkout = res.data.checkoutLineItemsUpdate.checkout;

          saveCheckout(checkout);

          dispatch({
            type: "UPDATELINEITEM_SUCCESS",
            payload: { data: checkout },
          });
        });
    }
  };
};

// Removes lineItem from checkout
export const removeLineItemInCart = (lineItemId, checkoutId) => {
  return async (dispatch, getState, { clientShopify }) => {
    const checkoutIsFinished = await dispatch(getCheckout(checkoutId));

    if (!checkoutIsFinished) {
      clientShopify
        .mutate({
          mutation: checkoutLineItemsRemove,
          variables: {
            checkoutId: checkoutId,
            lineItemIds: [lineItemId],
          },
        })
        .then((res) => {
          const checkout = res.data.checkoutLineItemsRemove.checkout;

          saveCheckout(checkout);

          dispatch({
            type: "REMOVELINEITEM_SUCCESS",
            payload: { data: checkout },
          });
        });
    }
  };
};

// Stores checkout data in local storage
function saveCheckout(checkout) {
  if (checkout) {
    localStorage.setItem("checkout", JSON.stringify(checkout));
  } else {
    localStorage.removeItem("checkout");
  }
}
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
