//> Reducers
// Authentication
import shopReducer from "./shopReducer";

//> Redux
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  /* Shopify */
  shop: shopReducer,
});

export default rootReducer;

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
