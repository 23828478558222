//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";

//> CSS
import "./HomePage.scss";
//> Components
import { Selector } from "../../organisms";
//#endregion

//#region > Components
class HomePage extends React.Component {
  render() {
    return (
      <>
        <Selector selectItem={this.selectItem} />
      </>
    );
  }
}
//#endregion

//#region > Exports
export default HomePage;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
