//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { BrowserRouter as Router } from "react-router-dom";
//> Redux
// Connect
import { connect } from "react-redux";

//> Components
import { Footer, Navbar, CookieModal } from "./components/molecules";
// Starts the page on top when reloaded or redirected
import { ScrollToTop } from "./components/atoms";
//> Redux
import { initShopify, getProducts } from "./store/actions/shopifyActions";
//> Routes
import Routes from "./Routes";
//#endregion

//#region > Components
class App extends React.Component {
  componentDidMount = () => {
    this.checkCookies();

    this.props.getProducts();
    this.props.initShopify();
  };

  saveCookie = () => {
    this.checkCookies();
  };

  checkCookies = () => {
    // Create custom user id for tracking
    let userId = localStorage.getItem("userId");

    if (!userId) {
      const sha256 = require("js-sha256");

      userId = sha256.create();
      localStorage.setItem("userId", userId);
    }
  };

  render() {
    return (
      <Router>
        <ScrollToTop>
          <div className="flyout">
            <Navbar />
            <main>
              <Routes />
              <CookieModal saveCookie={this.saveCookie} />
            </main>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return {
    products: state?.shop?.payload,
    shopData: state?.shop,
  };
};

const mapDispatchToProps = {
  initShopify,
  getProducts,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(App);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
