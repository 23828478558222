//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBView, MDBMask, MDBInput } from "mdbreact";
//> Textarea
import TextareaAutosize from "react-textarea-autosize";
//#endregion

//#region > Components
class TextSelector extends React.Component {
  state = { rows: 1 };

  componentDidUpdate = () => {
    const lines = this.props.value.split(/\r*\n/);

    if (this.state.rows !== lines.length) {
      this.setState({
        rows: lines.length,
      });
    }
  };

  setValue = (pos, val) => {
    this.setState({
      ["textarea-" + pos]: val,
    });
  };

  render() {
    return (
      <MDBView>
        <img
          src={require("../../../assets/blank.jpg")}
          alt="Blank Plauderkiste"
          className="img-fluid"
        />
        <MDBMask className="d-flex flex-center">
          <TextareaAutosize
            value={this.props.value}
            onChange={(e) => this.props.getValue(e.target.value)}
            maxLength={200}
            onKeyPress={(e) => {
              if (e.key === "Enter") e.preventDefault();
            }}
          />
        </MDBMask>
      </MDBView>
    );
  }
}
//#endregion

//#region > Exports
export default TextSelector;
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
