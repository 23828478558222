//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// This serves as an entry point to the DOM and server renderers for React
import ReactDOM from "react-dom";
//> Apollo
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
//> Redux
// Store, Middleware, Compose
import { createStore, applyMiddleware, compose } from "redux";
// Provider
import { Provider } from "react-redux";
// Thunk
import thunk from "redux-thunk";
//> Font Awesome
// Font Awesome is an awesome icon library
import "@fortawesome/fontawesome-free/css/all.min.css";
//> Bootstrap
import "bootstrap-css-only/css/bootstrap.min.css";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import "mdbreact/dist/css/mdb.css";

//> CSS
// Root SCSS file
import "./index.scss";
//> Components
// Root component
import App from "./App";
//> Redux
// Reducer
import rootReducer from "./store/reducers/rootReducer";
//#endregion

//#region > Config
//> Shopify API
// API Link
const httpLinkShopify = createHttpLink({
  uri: "https://www.plauderkiste.com//api/graphql",
});

// Storefront access token
const middlewareLinkShopify = setContext(() => ({
  headers: {
    "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_STOREFRONT_TOKEN,
  },
}));

// Apollo Client
const clientShopify = new ApolloClient({
  link: middlewareLinkShopify.concat(httpLinkShopify),
  cache: new InMemoryCache(),
});

// Create Redux data-store and store it in store and apply thunk middleware
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(
      thunk.withExtraArgument({
        /* Shopify Storefront binding */
        clientShopify,
      })
    )
  )
);
//#endregion

//#region > Render
// Render the DOM
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
