//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { withRouter } from "react-router-dom";
//> Redux
// Connect
import { connect } from "react-redux";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBIcon,
  MDBFormInline,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBModalHeader,
  MDBView,
  MDBMask,
  MDBAlert,
} from "mdbreact";

//> CSS
import "./cookie.scss";
//#endregion

//#region > Components
class ModalPage extends React.Component {
  state = {
    modal: localStorage.getItem("gdpr")
      ? localStorage.getItem("gdpr") < new Date().getTime()
        ? true
        : false
      : true,
    gdpr: false,
    thirdParties: false,
  };

  toggle = () => {
    this.setState(
      {
        modal: !this.state.modal,
      },
      () => {
        localStorage.setItem("gdpr", new Date().getTime() + 1800000);
      }
    );
  };

  render() {
    const { products } = this.props;

    return (
      <MDBModal
        isOpen={this.state.modal}
        toggle={this.toggle}
        disableFocusTrap={false}
        keyboard={false}
        size="lg"
        className="cookie-modal my-5"
      >
        <MDBModalHeader className="text-center">
          <p className="mb-0 w-100">Willkommen im Plauderkiste Konfigurator</p>
        </MDBModalHeader>
        <MDBModalBody className="text-center py-4">
          <MDBRow className="d-flex justify-content-center mb-3 w-50 mx-auto">
            {products &&
              products.map((product, p) => {
                const info = product.node;
                const image = info.images.edges[0].node.transformedSrc
                  ? info.images.edges[0].node.transformedSrc
                  : info.images.edges[0].node.originalSrc;

                return (
                  <MDBCol col="2">
                    <MDBView>
                      <img src={image} alt={info.title} className="img-fluid" />
                      <MDBMask />
                    </MDBView>
                  </MDBCol>
                );
              })}
          </MDBRow>
          {/*<p className="text-muted lead">
            Im Konfigurator kannst Du Deine individuelle Plauderkiste gestalten.
            </p>*/}
          <p className="small">
            Hinweis zu Rechten Dritter: Mit dem Upload jeglicher Inhalte (zB
            Fotos, Abbildungen, Texte etc.) zur Erstellung einer Plauderkiste
            bestätigen Sie, das Recht zu besitzen, diese für Ihre Zwecke
            verwenden zu dürfen. Bei unerlaubtem Gebrauch geschützter oder gar
            gesetzlich verbotener Inhalte werden jegliche rechtliche
            Konsequenzen sowie Forderungen zur Gänze an Sie verwiesen.
          </p>
          <MDBRow className="justify-content-center mt-4">
            <MDBCol lg="8">
              <MDBAlert color="info">
                <MDBIcon icon="wifi" />
                <p className="mb-0">
                  <span className="font-weight-bold">Kleiner Tipp</span>: Es
                  wird empfohlen, dass Du über WLAN mit dem Internet verbunden
                  bist.
                </p>
              </MDBAlert>
            </MDBCol>
          </MDBRow>
          <div className="text-left">
            <MDBInput
              label={
                <span className="small">
                  Ich habe die{" "}
                  <a
                    href="https://plauderkiste.com/policies/privacy-policy"
                    target="_blank"
                  >
                    Datenschutzerklärung
                  </a>{" "}
                  gelesen und akzeptiere diese.
                </span>
              }
              filled
              checked={this.state.gdpr}
              name="gdpr"
              type="checkbox"
              id="gdprCheckbox"
              containerClass="mt-4 ml-0 pl-0 mb-0"
              onChange={(e) => this.setState({ gdpr: e.target.checked })}
            />
            <MDBInput
              label={
                <span className="small">
                  Hiermit bestätige ich den Hinweis zu Rechten Dritter gelesen
                  zu haben und in vollem Umfang damit einverstanden zu sein.
                </span>
              }
              filled
              checked={this.state.thirdParties}
              name="thirdParties"
              type="checkbox"
              id="thirdPartiesCheckbox"
              containerClass="mt-0 ml-0 pl-0"
              onChange={(e) =>
                this.setState({ thirdParties: e.target.checked })
              }
            />
          </div>
          <p className="text-muted"></p>
          <MDBRow className="justify-content-center align-items-center mt-4">
            <MDBCol md="6">
              <MDBBtn color="blue" outline href="https://www.plauderkiste.com">
                <MDBIcon icon="angle-left" />
                Zurück zur Website
              </MDBBtn>
            </MDBCol>
            <MDBCol md="6">
              <MDBBtn
                color="blue"
                disabled={!this.state.gdpr || !this.state.thirdParties}
                onClick={this.toggle}
              >
                Jetzt starten
                <MDBIcon icon="chevron-circle-right" className="ml-2" />
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBModalBody>
      </MDBModal>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return {
    products: state?.shop?.payload,
  };
};
//#endregion

//#region > Exports
export default connect(mapStateToProps)(withRouter(ModalPage));
//#endregion
/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
