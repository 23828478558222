export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.readAsDataURL(blob);
  });

export const fetchAsBlob = (url) =>
  fetch(url).then((response) => response.blob());

export const b64toBlob = (image) => {
  return fetch(image).then((response) => response.blob());
};
