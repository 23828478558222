//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
// DOM bindings for React Router
import { Route, Switch, withRouter } from "react-router-dom";
//> Downscale
import downscale from "downscale";
//> UUID
import { v4 as uuidv4 } from "uuid";
//> Redux
// Connect
import { connect } from "react-redux";

import { toast, ToastContainer } from "mdbreact";

//> Components
/**
 * HomePage: A basic template page
 */
import { HomePage, MessagePage, CartPage } from "./components/pages";
//> Components
import { Standard, Bilderwelt, Puzzle } from "./components/organisms/types";
//> Redux
import { addVariantToCart } from "./store/actions/shopifyActions";
//#endregion

//#region > Functions
// Creates a file out of a blob
function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: "image/jpeg" });
}
//#endregion

//#region > Config
const DEFAULT_FILE_WIDTH = 1700;
const DEFAULT_FILE_HEIGHT = 1700;
//#endregion

//#region > Components
class Routes extends React.Component {
  addToCardStandard = async (
    variantId,
    results,
    massiveVoid,
    oldPdf,
    firstResult
  ) => {
    const uuid = oldPdf ? oldPdf : uuidv4();

    let textsRes = [];
    let imagesRes = [];

    results.forEach((element) => {
      textsRes = [...textsRes, element.text];
      imagesRes = [...imagesRes, element.image];
    });

    const previewImage = await downscale(firstResult.renderedImage, 50, 50);

    // Get checkout token
    const { checkout } = this.props;
    const id = atob(checkout?.id);
    // Split by "/"
    let checkoutParts = id.split("/");
    // Split by "?"
    checkoutParts = checkoutParts[checkoutParts.length - 1]?.split("?");
    const checkoutToken = checkoutParts[0];

    // Create form data
    const data = new FormData();

    data.append("id", uuid);
    data.append("checkout_token", checkoutToken);

    if (imagesRes && imagesRes.length > 0) {
      // Send multiple files
      let bar = new Promise(async (resolve, reject) => {
        let counter = 0;
        let res = [];

        imagesRes.forEach(async (img, i) => {
          const file = dataURLtoFile(
            img,
            "plauderkiste-" + uuid + "-" + i + ".png"
          );

          // Scale to 1500x1500
          const scaled = await downscale(
            file,
            DEFAULT_FILE_WIDTH,
            DEFAULT_FILE_HEIGHT
          );

          // Create file to send
          const fileResized = dataURLtoFile(
            scaled,
            "plauderkiste-" + uuid + "-" + i + ".png"
          );

          // Save image on correct position
          res[i] = fileResized;

          if (fileResized) {
            counter++;
          }

          // If finished resolve promise
          if (counter === imagesRes.length) {
            resolve(res);
          }
        });
      });

      bar.then(async (res) => {
        if (res) {
          if (textsRes && textsRes.length > 0) {
            // Send multiple files
            textsRes.forEach((text) => {
              data.append("text", text);
            });
          }

          // Push files to binary
          res.forEach((fileResized) => {
            data.append("image", fileResized);
          });

          const serverRes = await fetch(
            process.env.REACT_APP_BASEURL + "plauder/",
            {
              method: "POST",
              body: data,
            }
          )
            .then((response) => {
              switch (response.status) {
                case 200: // OK
                  return { status: true };
                case 400: // Bad Request
                  toast.error(
                    "Das hat leider nicht geklappt, bitte probieren Sie es erneut.",
                    {
                      closeButton: false,
                    }
                  );
                  return { status: false };
                default:
                  toast.error(
                    "Wir haben leider Probleme mit der Seververbindung, " +
                      "bitte versuchen Sie es später erneut.",
                    {
                      closeButton: false,
                    }
                  );
                  return { status: false };
              }
            })
            .catch((error) => {
              return { status: false, error };
            });

          if (serverRes.status) {
            const inCartLS = localStorage.getItem("inCart-" + variantId);

            if (inCartLS) {
              const currentInCard = JSON.parse(inCartLS);
              const theCurrentCard = currentInCard.filter(
                (element) => element.massiveVoid === massiveVoid
              );

              if (theCurrentCard.length > 0) {
                const otherCurrentInCard = currentInCard.filter(
                  (element) => element.massiveVoid !== massiveVoid
                );

                localStorage.setItem(
                  "inCart-" + variantId,
                  JSON.stringify([
                    ...otherCurrentInCard,
                    {
                      pdfId: uuid,
                      massiveVoid,
                      preview: previewImage,
                      amount: theCurrentCard[0]?.amount
                        ? theCurrentCard[0]?.amount
                        : 1,
                      created: theCurrentCard[0]?.created
                        ? theCurrentCard[0]?.created
                        : new Date().getTime(),
                    },
                  ])
                );

                // Delete save version, because in cart
                localStorage.removeItem(variantId);

                this.props.history.push("/cart");
              } else {
                localStorage.setItem(
                  "inCart-" + variantId,
                  JSON.stringify([
                    ...currentInCard,
                    {
                      pdfId: uuid,
                      massiveVoid,
                      preview: previewImage,
                      amount: 1,
                      created: new Date().getTime(),
                    },
                  ])
                );

                this.props.addVariantToCart(
                  variantId,
                  1,
                  this.props.checkout.id
                );

                // Delete save version, because in cart
                localStorage.removeItem(variantId);

                this.props.history.push("/cart");
              }
            } else {
              localStorage.setItem(
                "inCart-" + variantId,
                JSON.stringify([
                  {
                    pdfId: uuid,
                    massiveVoid,
                    preview: previewImage,
                    amount: 1,
                    created: new Date().getTime(),
                  },
                ])
              );

              this.props.addVariantToCart(variantId, 1, this.props.checkout.id);

              // Delete save version, because in cart
              localStorage.removeItem(variantId);

              this.props.history.push("/cart");
            }
          }
        }
      });
    }
  };

  addToCardBilderwelt = async (
    variantId,
    results,
    massiveVoid,
    oldPdf,
    firstResult
  ) => {
    const uuid = oldPdf ? oldPdf : uuidv4();

    let img1Res = [];
    let img2Res = [];

    results.forEach((element) => {
      img1Res = [...img1Res, element.image1];
      img2Res = [...img2Res, element.image2];
    });

    const previewImage = await downscale(firstResult.renderedImage1, 50, 50);

    // Get checkout token
    const { checkout } = this.props;
    const id = atob(checkout?.id);
    // Split by "/"
    let checkoutParts = id.split("/");
    // Split by "?"
    checkoutParts = checkoutParts[checkoutParts.length - 1]?.split("?");
    const checkoutToken = checkoutParts[0];

    // Create form data
    const data = new FormData();

    data.append("id", uuid);
    data.append("checkout_token", checkoutToken);

    if (img1Res && img1Res.length > 0 && img2Res && img2Res.length) {
      // Send multiple files
      let bar = new Promise(async (resolve, reject) => {
        let counter = 0;
        let res = [];
        let arr1 = [0, 2, 4, 6, 8, 10, 12, 14, 16];
        let arr2 = [1, 3, 5, 7, 9, 11, 13, 15, 17];

        let imgAll = [];

        img1Res.forEach((img, i) => {
          imgAll[arr1[i]] = img;
        });

        img2Res.forEach((img, i) => {
          imgAll[arr2[i]] = img;
        });

        imgAll.forEach(async (img, i) => {
          const file = dataURLtoFile(
            img,
            "plauderkiste-" + uuid + "-" + i + ".png"
          );

          // Scale to 1500x1500
          const scaled = await downscale(
            file,
            DEFAULT_FILE_WIDTH,
            DEFAULT_FILE_HEIGHT
          );

          // Create file to send
          const fileResized = dataURLtoFile(
            scaled,
            "plauderkiste-" + uuid + "-" + i + ".png"
          );

          // Save image on correct position
          res[i] = fileResized;

          if (fileResized) {
            counter++;
          }

          // If finished resolve promise
          if (counter === 18) {
            resolve(res);
          }
        });
      });

      bar.then(async (res) => {
        console.log(res);
        if (res) {
          // Push files to binary
          res.forEach((fileResized) => {
            data.append("image", fileResized);
          });

          const serverRes = await fetch(
            process.env.REACT_APP_BASEURL + "plauder/",
            {
              method: "POST",
              body: data,
            }
          )
            .then((response) => {
              switch (response.status) {
                case 200: // OK
                  return { status: true };
                case 400: // Bad Request
                  toast.error(
                    "Das hat leider nicht geklappt, bitte probieren Sie es erneut.",
                    {
                      closeButton: false,
                    }
                  );
                  return { status: false };
                default:
                  toast.error(
                    "Wir haben leider Probleme mit der Seververbindung, " +
                      "bitte versuchen Sie es später erneut.",
                    {
                      closeButton: false,
                    }
                  );
                  return { status: false };
              }
            })
            .catch((error) => {
              return { status: false, error };
            });

          if (serverRes.status) {
            const inCartLS = localStorage.getItem("inCart-" + variantId);

            if (inCartLS) {
              const currentInCard = JSON.parse(inCartLS);
              const theCurrentCard = currentInCard.filter(
                (element) => element.massiveVoid === massiveVoid
              );

              if (theCurrentCard.length > 0) {
                const otherCurrentInCard = currentInCard.filter(
                  (element) => element.massiveVoid !== massiveVoid
                );

                localStorage.setItem(
                  "inCart-" + variantId,
                  JSON.stringify([
                    ...otherCurrentInCard,
                    {
                      pdfId: uuid,
                      massiveVoid,
                      preview: previewImage,
                      amount: theCurrentCard[0]?.amount
                        ? theCurrentCard[0]?.amount
                        : 1,
                      created: theCurrentCard[0]?.created
                        ? theCurrentCard[0]?.created
                        : new Date().getTime(),
                    },
                  ])
                );

                // Delete save version, because in cart
                localStorage.removeItem(variantId);

                this.props.history.push("/cart");
              } else {
                localStorage.setItem(
                  "inCart-" + variantId,
                  JSON.stringify([
                    ...currentInCard,
                    {
                      pdfId: uuid,
                      massiveVoid,
                      preview: previewImage,
                      amount: 1,
                      created: new Date().getTime(),
                    },
                  ])
                );

                this.props.addVariantToCart(
                  variantId,
                  1,
                  this.props.checkout.id
                );

                // Delete save version, because in cart
                localStorage.removeItem(variantId);

                this.props.history.push("/cart");
              }
            } else {
              localStorage.setItem(
                "inCart-" + variantId,
                JSON.stringify([
                  {
                    pdfId: uuid,
                    massiveVoid,
                    preview: previewImage,
                    amount: 1,
                    created: new Date().getTime(),
                  },
                ])
              );

              this.props.addVariantToCart(variantId, 1, this.props.checkout.id);

              // Delete save version, because in cart
              localStorage.removeItem(variantId);

              this.props.history.push("/cart");
            }
          }
        }
      });
    }
  };

  addToCardPuzzle = async (
    variantId,
    results,
    massiveVoid,
    oldPdf,
    firstResult
  ) => {
    const uuid = oldPdf ? oldPdf : uuidv4();

    let imgRes = [];
    let textRes = [];
    let bg = [];

    results.forEach((element) => {
      if (element.image && element.text) {
        imgRes = [...imgRes, element.image];
        textRes = [...textRes, element.text];
      } else {
        bg = [...bg, element];
      }
    });

    const previewImage = await downscale(firstResult.renderedImage, 50, 50);

    // Get checkout token
    const { checkout } = this.props;
    const id = atob(checkout?.id);
    // Split by "/"
    let checkoutParts = id.split("/");
    // Split by "?"
    checkoutParts = checkoutParts[checkoutParts.length - 1]?.split("?");

    const checkoutToken = checkoutParts[0];

    // Create form data
    const data = new FormData();

    data.append("id", uuid);
    data.append("checkout_token", checkoutToken);

    let bar = new Promise(async (resolve, reject) => {
      let counter = 0;
      let res = [];

      if (bg) {
        for (let index = 0; index < 9; index++) {
          const file = dataURLtoFile(
            bg[index],
            "plauderkiste-" + uuid + "b-g-" + index + ".jpg"
          );

          console.log(bg);

          data.append("large", file);
        }
      }

      if (imgRes && imgRes.length > 0) {
        imgRes.forEach(async (img, i) => {
          const file = dataURLtoFile(
            img,
            "plauderkiste-" + uuid + "-" + i + ".jpg"
          );
        
          // Scale to width / height
          const scaled = await downscale(
            file,
            DEFAULT_FILE_WIDTH,
            DEFAULT_FILE_HEIGHT * 0.7162471
          );

          // Create file to send
          const fileResized = dataURLtoFile(
            scaled,
            "plauderkiste-" + uuid + "-" + i + ".jpg"
          );

          // Save image on correct position
          res[i] = fileResized;

          if (fileResized) {
            counter++;
          }

          // If finished resolve promise
          if (counter === imgRes.length) {
            resolve(res);
          }
        });
      }
    });

    bar.then(async (res) => {
      if (res) {
        if (textRes && textRes.length > 0) {
          // Send multiple files
          textRes.forEach((text) => {
            data.append("text", text);
          });
        }

        // Push files to binary
        res.forEach((fileResized) => {
          data.append("image", fileResized);
        });

        const serverRes = await fetch(
          process.env.REACT_APP_BASEURL + "plauder/",
          {
            method: "POST",
            body: data,
          }
        )
          .then((response) => {
            switch (response.status) {
              case 200: // OK
                return { status: true };
              case 400: // Bad Request
                toast.error(
                  "Das hat leider nicht geklappt, bitte probieren Sie es erneut.",
                  {
                    closeButton: false,
                  }
                );
                return { status: false };
              default:
                toast.error(
                  "Wir haben leider Probleme mit der Seververbindung, " +
                    "bitte versuchen Sie es später erneut.",
                  {
                    closeButton: false,
                  }
                );
                return { status: false };
            }
          })
          .catch((error) => {
            return { status: false, error };
          });

        if (serverRes.status) {
          const inCartLS = localStorage.getItem("inCart-" + variantId);

          if (inCartLS) {
            const currentInCard = JSON.parse(inCartLS);
            const theCurrentCard = currentInCard.filter(
              (element) => element.massiveVoid === massiveVoid
            );

            if (theCurrentCard.length > 0) {
              const otherCurrentInCard = currentInCard.filter(
                (element) => element.massiveVoid !== massiveVoid
              );

              localStorage.setItem(
                "inCart-" + variantId,
                JSON.stringify([
                  ...otherCurrentInCard,
                  {
                    pdfId: uuid,
                    massiveVoid,
                    preview: previewImage,
                    amount: theCurrentCard[0]?.amount
                      ? theCurrentCard[0]?.amount
                      : 1,
                    created: theCurrentCard[0]?.created
                      ? theCurrentCard[0]?.created
                      : new Date().getTime(),
                  },
                ])
              );

              // Delete save version, because in cart
              localStorage.removeItem(variantId);

              this.props.history.push("/cart");
            } else {
              localStorage.setItem(
                "inCart-" + variantId,
                JSON.stringify([
                  ...currentInCard,
                  {
                    pdfId: uuid,
                    massiveVoid,
                    preview: previewImage,
                    amount: 1,
                    created: new Date().getTime(),
                  },
                ])
              );

              this.props.addVariantToCart(variantId, 1, this.props.checkout.id);

              // Delete save version, because in cart
              localStorage.removeItem(variantId);

              this.props.history.push("/cart");
            }
          } else {
            localStorage.setItem(
              "inCart-" + variantId,
              JSON.stringify([
                {
                  pdfId: uuid,
                  massiveVoid,
                  preview: previewImage,
                  amount: 1,
                  created: new Date().getTime(),
                },
              ])
            );

            this.props.addVariantToCart(variantId, 1, this.props.checkout.id);

            // Delete save version, because in cart
            localStorage.removeItem(variantId);

            this.props.history.push("/cart");
          }
        }
      }
    });
  };

  render() {
    return (
      <>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route
            exact
            path="/about"
            render={(props) => <MessagePage {...props} />}
          />
          <Route
            exact
            path="/standard"
            render={(props) => (
              <Standard
                count={9}
                selection={
                  this.props.products &&
                  this.props.products.filter(
                    (product) =>
                      product?.node?.id ===
                      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY1NTg5MzAyMDY5MDQ="
                  )[0]?.node
                }
                addToCard={this.addToCardStandard}
                editData={this.props.editData}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/bilderwelt"
            render={(props) => (
              <Bilderwelt
                count={9}
                selection={
                  this.props.products &&
                  this.props.products.filter(
                    (product) =>
                      product?.node?.id ===
                      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY1NTg5MzEyNTU0ODA="
                  )[0]?.node
                }
                addToCard={this.addToCardBilderwelt}
                editData={this.props.editData}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/puzzle"
            render={(props) => (
              <Puzzle
                count={10}
                selection={
                  this.props.products &&
                  this.props.products.filter(
                    (product) =>
                      product?.node?.id ===
                      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY1NTg5MzI1OTg5Njg="
                  )[0]?.node
                }
                addToCard={this.addToCardPuzzle}
                editData={this.props.editData}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/cart"
            render={(props) => <CartPage {...props} />}
          />
          <Route
            render={function () {
              return <h1>Not Found</h1>;
            }}
          />
        </Switch>
        <ToastContainer newestOnTop={true} autoClose={6000} />
      </>
    );
  }
}
//#endregion

//#region > Functions
const mapStateToProps = (state) => {
  return {
    products: state?.shop?.payload,
    shopData: state?.shop,
    checkout: state.shop.checkout,
  };
};

const mapDispatchToProps = {
  addVariantToCart,
};
//#endregion

//#region > Exports
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
