//#region > Imports
//> React
// Contains all the functionality necessary to define React components
import React from "react";
//> Additional
// Framework for building content loaders
import ContentLoader from "react-content-loader";
//> MDB
// "Material Design for Bootstrap" is a great UI design framework
import { MDBRow, MDBCol, MDBView, MDBMask } from "mdbreact";
//#endregion

//#region > Components
export const RatingPlaceholder = () => {
  return (
    <MDBView>
      <ContentLoader
        speed={2}
        width={400}
        height={24}
        viewBox="0 0 400 20"
        backgroundColor="#E9ECEF"
        foregroundColor="#f5f7fa"
      >
        <circle cx="10" cy="5" r="5" />
        <circle cx="30" cy="5" r="5" />
        <circle cx="50" cy="5" r="5" />
        <circle cx="70" cy="5" r="5" />
        <circle cx="90" cy="5" r="5" />
        <rect x="120" y="2" rx="3" ry="3" width="200" height="8" />
      </ContentLoader>
      <MDBMask></MDBMask>
    </MDBView>
  );
};

export const ShopSectionPlaceholder = () => {
  return (
    <MDBRow className="d-flex justify-content-center w-100">
      {Array.from({ length: 3 }, (_, i) => (
        <ProductItemPlaceholder key={i} />
      ))}
    </MDBRow>
  );
};

export const ProductItemPlaceholder = () => {
  return (
    <MDBCol md="4" className="d-flex justify-content-center">
      <MDBView>
        <ContentLoader
          speed={2}
          width={300}
          height={350}
          viewBox="0 0 300 500"
          backgroundColor="#E9ECEF"
          foregroundColor="#f5f7fa"
          style={{ width: "100%", height: "100%" }}
        >
          <rect x="36" y="27" rx="0" ry="0" width="274" height="275" />
          <rect x="59" y="446" rx="0" ry="0" width="226" height="47" />
          <rect x="130" y="344" rx="0" ry="0" width="83" height="20" />
          <rect x="29" y="381" rx="0" ry="0" width="289" height="16" />
          <rect x="29" y="401" rx="0" ry="0" width="289" height="16" />
        </ContentLoader>
        <MDBMask></MDBMask>
      </MDBView>
    </MDBCol>
  );
};

export const OptionsPlaceholder = (props) => (
  <MDBView>
    <ContentLoader
      speed={2}
      width={350}
      height={200}
      viewBox="0 0 350 200"
      backgroundColor="#E9ECEF"
      foregroundColor="#f5f7fa"
      {...props}
    >
      <rect x="5" y="28" rx="5" ry="5" width="83" height="47" />
      <rect x="1" y="3" rx="11" ry="11" width="69" height="20" />
      <rect x="98" y="28" rx="5" ry="5" width="83" height="47" />
      <rect x="192" y="28" rx="5" ry="5" width="83" height="47" />
      <rect x="6" y="135" rx="5" ry="5" width="130" height="47" />
      <rect x="145" y="135" rx="5" ry="5" width="130" height="47" />
      <rect x="1" y="110" rx="11" ry="11" width="69" height="20" />
    </ContentLoader>
    <MDBMask></MDBMask>
  </MDBView>
);

export const OrderPlaceholder = (props) => (
  <MDBView>
    <div className="d-flex justify-content-between">
      <ContentLoader
        speed={2}
        width={200}
        height={115}
        viewBox="0 0 200 115"
        backgroundColor="#E9ECEF"
        foregroundColor="#f5f7fa"
        {...props}
      >
        <rect x="2" y="65" rx="7" ry="7" width="131" height="43" />
        <rect x="2" y="31" rx="9" ry="9" width="74" height="16" />
        <rect x="2" y="2" rx="9" ry="9" width="118" height="17" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={181}
        height={115}
        viewBox="0 0 181 115"
        backgroundColor="#E9ECEF"
        foregroundColor="#f5f7fa"
        {...props}
      >
        <rect x="82" y="1" rx="5" ry="5" width="93" height="25" />
        <rect x="25" y="5" rx="7" ry="7" width="49" height="14" />
        <rect x="74" y="34" rx="6" ry="6" width="104" height="13" />
        <rect x="122" y="60" rx="8" ry="8" width="54" height="14" />
        <rect x="55" y="32" rx="91" ry="91" width="16" height="16" />
      </ContentLoader>
    </div>
    <MDBMask></MDBMask>
  </MDBView>
);
//#endregion

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */
